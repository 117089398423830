import Select from "react-select";
import { formatDeviceName } from "../utils";
import useUIText from "../hooks/translation";
import { useMemo } from "react";

const AutoSelect = ({ device, items, setSelectedDevice }) => {
	const uiText = useUIText();
	const devices = useMemo(() => {
		return items
			.map((device) => device.deviceName)
			.map((item) => ({ value: item, label: formatDeviceName(item) }));
	}, [items])

	return (
		<Select
			className="basic-single"
			classNamePrefix="select"
			isClearable
			isSearchable
			options={devices}
			value={device? {
				value: device,
				label: formatDeviceName(device)
			}: null}
			placeholder={uiText?.form?.deviceModelPlaceholder2}
			onChange={(value) => setSelectedDevice(value?.value || "")}
		/>
	);
};

export default AutoSelect;
